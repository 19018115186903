<template>
     <!-- 智能问答-全部问题 -->
    <div class="whole">
      <!-- 上方 -->
        <div class="head">
          <!-- 问题条数 -->
          <div class="head_problem" >共546个问题</div>
          <!-- 分类内容 -->
          <div class="classification">
          <div class="center">
                 <V-Classification></V-Classification>
          </div>
        </div>
             </div>

        <!-- 中间 -->
          <!-- 中间导航 -->
          <div class="center_Navigation">问题全部
              <span>></span>
              合成生物学
              <span class="down"></span>
         </div>
          <!-- 内容 -->
          <div class="content">
                  <!-- 问题 -->
                  <div class="long">
                  <V-Hotspot></V-Hotspot>
                  </div>
          </div>

            <!-- 分页 -->
            <div class="page-nav">
                <el-pagination
                    background
                    layout="prev, pager, next"
                    :hide-on-single-page="hidePageNav"
                    :total="totlePage"
                    :page-size="pageSize"
                    @prev-click="prevPage" @next-click="nextPage"  @current-change="currentPage">
                </el-pagination>
            </div>
    </div>
</template>
<script>
import Classification from "@/views/wisdom-answer/index/all-questions/problem_classification/index.vue"
import Hotspot from "@/components/all_questions/reward/index.vue"
export default {
    data(){
      return{
    problem:1,
    //   hidePageNav:true,
    //   pageNum:0,
    //   pageSize:12,
    //   totlePage:0,

      }
    },
  components:{
    'V-Classification':Classification,
    'V-Hotspot':Hotspot
  }, 

   created: function(){
 
     },
  methods:{
   

  }
}
</script>
<style scoped>
@import "~@/styles/page/index.css";
 .whole{
    width: 100%;
    display: inline-block;
}
.head{
    width: 100%;
    display: inline-block;
} 
.head_problem{
width: 788px;
text-align: right;
margin: auto;
font-size: 12px;
margin-bottom: 5px;
} 
 .classification{
width: 100%;
text-align: center;
background-color: white;
}
.center{
width: 788px;
margin: auto; 

} 

.center_Navigation{
width: 788px;
margin: auto;
text-align: left;
height: 30px;
line-height: 30px;
font-size: 13px;
margin-top: 20px;
}
.down{
    width: 20px;
    height:20px;
    display: inline-block;
    background-image: url('../../../../assets/icon/down.png');
    background-repeat: no-repeat;
    position: relative;
    top: 12px;
    left: 3px;
}
.content{
width: 788px;
margin: auto;
background-color: white;
}



.condition_offer{
color: #0084FF;
padding-bottom: 10px;
border-bottom:2px solid #0084FF;

}
.long{
    width: 768px;
 /* border: 1px solid orangered; */
 margin-left: 10px;
}

/* .Specific_issues{
width: 98%;
margin: auto;
margin-top: 10px;
padding-right: 10px;
overflow: hidden;
} */
/* 分页 */

 .page-nav{
    width: 788px;
    margin: auto;
    position: relative;
}
</style>
